import React from 'react';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import PageFooter from '../components/PageFooter';
import Banner from '../components/Banner';
import Tile from '../components/Tile';

const privacyTitle = 'Privacyverklaring';
const privacyText = '<p>Vanaf 25 mei 2018 geldt de Algemene Verordening Gegevensbescherming (AVG). De privacywetgeving in de hele Europese Unie is vanaf die datum gelijk. Binnen deze wet staan openheid en transparantie in het verkrijgen en opslaan van persoonlijke gegevens centraal.<br/><br/>\nUw privacy is voor ons net zo belangrijk als voor u. Wij gaan zorgvuldig met uw gegevens om en maken daarom enkel gebruik van uw persoonsgegevens ten behoeve van onze klantrelatie. Dit betekent dat wij de gevraagde services en diensten kunnen uitvoeren en u kunnen informeren over direct aan u gerelateerde onderwerpen.</p>';

const IndexPage = () => (
  <Layout>
    <Banner />
    <section id="main" class="tile">
      <Tile title={privacyTitle} message={privacyText} messageType="privacy"/>
      <a href="/">Sluiten</a>
    </section>
    <PageFooter />
  </Layout>
);

export default IndexPage;
