import React from 'react';

import config from '../../config';

const Tile = props => {
    const messageType = props.messageType;
    return (
      <header>
          <h1>{props.title}</h1>
          <div dangerouslySetInnerHTML={{__html: props.message}} />
      </header>
    );
  }
  
  export default Tile;